export const fromPathTo = () => {
  return {
    // Basic
    "/basic": "https://with.zonayed.me/js-basic",
    "/basic/post-0": "https://with.zonayed.me/post/js-what-why-when",
    "/basic/post-1": "https://with.zonayed.me/post/js-where-how",
    "/basic/post-2": "https://with.zonayed.me/post/js-basic-chrome-dev-console",
    "/basic/post-3": "https://with.zonayed.me/post/js-variable-data-type",
    "/basic/post-4": "https://with.zonayed.me/post/js-operator",
    "/basic/post-5": "https://with.zonayed.me/post/js-conditional-statement",
    "/basic/post-6": "https://with.zonayed.me/post/js-loop",
    "/basic/post-7": "https://with.zonayed.me/post/js-array",
    "/basic/post-8": "https://with.zonayed.me/post/js-basic-object",
    "/basic/post-9": "https://with.zonayed.me/post/js-basic-function",
    "/basic/post-10": "https://with.zonayed.me/post/js-statement-expression",
    "/basic/post-11": "https://with.zonayed.me/post/js-dry-principle",
    "/basic/post-12": "https://with.zonayed.me/post/js-ecmascript",
    "/basic/post-13": "https://with.zonayed.me/post/js-behind-the-scene",
    "/basic/post-14": "https://with.zonayed.me/post/js-hoisting-introduction",
    "/basic/post-15": "https://with.zonayed.me/post/js-scope-introduction",
    "/basic/post-16": "https://with.zonayed.me/post/js-closures-introduction",
    "/basic/post-17": "https://with.zonayed.me/post/js-iife",
    // Advance
    "/advance": "https://with.zonayed.me/js-advance",
    "/advance/post-0": "https://with.zonayed.me/post/js-this-keyword",
    "/advance/post-1": "https://with.zonayed.me/post/js-call-bind-apply",
    "/advance/post-2": "https://with.zonayed.me/post/js-oop",
    "/advance/post-3":
      "https://with.zonayed.me/post/js-function-constructor-and-new-keyword",
    "/advance/post-4": "https://with.zonayed.me/post/js-inheritance",
    "/advance/post-5": "https://with.zonayed.me/post/js-strict-mode",
    // ES6
    "/es6": "https://with.zonayed.me/js-es6",
    "/es6/post-0": "https://with.zonayed.me/post/js-whats-new-in-es6",
    "/es6/post-1": "https://with.zonayed.me/post/js-let-const",
    "/es6/post-2": "https://with.zonayed.me/post/js-iife-es6",
    "/es6/post-3": "https://with.zonayed.me/post/js-template-literal",
    "/es6/post-4":
      "https://with.zonayed.me/post/js-arrow-function-and-lexical-this",
    "/es6/post-5": "https://with.zonayed.me/post/js-es6-array",
    "/es6/post-6": "https://with.zonayed.me/post/js-spread-operator",
    "/es6/post-7": "https://with.zonayed.me/post/js-destructuring",
    "/es6/post-8": "https://with.zonayed.me/post/js-rest-parameter",
    "/es6/post-9": "https://with.zonayed.me/post/js-default-parameter",
    "/es6/post-10": "https://with.zonayed.me/post/js-map-data-structure",
    "/es6/post-11": "https://with.zonayed.me/post/js-class",
    "/es6/post-12": "https://with.zonayed.me/post/js-class-and-inheritance",
    "/es6/post-13": "https://with.zonayed.me/post/js-es6-in-all-environment",
    // DOM
    "/dom": "https://with.zonayed.me/js-dom",
    "/dom/post-0": "https://with.zonayed.me/post/js-and-dom",
    "/dom/post-1": "https://with.zonayed.me/post/js-select-element",
    "/dom/post-2": "https://with.zonayed.me/post/js-play-with-element",
    "/dom/post-3": "https://with.zonayed.me/post/js-dom-event",
    "/dom/post-4": "https://with.zonayed.me/post/js-ajax",
    // Daily
    "/daily": "https://with.zonayed.me/js-daily",
    "/daily/post-0":
      "https://with.zonayed.me/post/js-first-class-higher-order-function",
    "/daily/post-1": "https://with.zonayed.me/post/js-map-filter-reduce",
    "/daily/post-2": "https://with.zonayed.me/post/js-some-every",
    "/daily/post-3": "https://with.zonayed.me/post/js-loop-in-object",
    "/daily/post-4": "https://with.zonayed.me/post/js-dot-bracket-notation",
    "/daily/post-5": "https://with.zonayed.me/post/js-math-object",
    "/daily/post-6": "https://with.zonayed.me/post/js-date-object",
    "/daily/post-7": "https://with.zonayed.me/post/js-logical-or-and-operator",
    "/daily/post-8":
      "https://with.zonayed.me/post/js-conditional-ternary-operator",
    "/daily/post-9": "https://with.zonayed.me/post/js-regex",
    "/daily/post-10": "https://with.zonayed.me/post/js-error-handling",
    "/daily/post-11": "https://with.zonayed.me/post/js-developer-console",
    "/daily/post-12": "https://with.zonayed.me/post/js-asynchronous",
    "/daily/post-13": "https://with.zonayed.me/post/js-callback-function",
    "/daily/post-14": "https://with.zonayed.me/post/js-promise",
    "/daily/post-15": "https://with.zonayed.me/post/js-async-await",
    // Algo DS
    "/algods": "https://with.zonayed.me/videos/js-algods",
    "/algods/post-0": "https://with.zonayed.me/video/PvR3i3_I6sM",
    // Soft Skills
    "/soft": "https://with.zonayed.me/",
    "/soft/post-0": "https://with.zonayed.me/",
    "/soft/post-1": "https://with.zonayed.me/post/gulp-at-a-glance",
    "/soft/post-2":
      "https://with.zonayed.me/post/react-starter-pack-from-scratch-webpack",
    // Main
    "/": "https://with.zonayed.me/js",
  };
};
