import { useEffect } from "react";
import { fromPathTo } from "./helpers";
import "./App.css";

function App() {
  useEffect(() => {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        if (!!registration) {
          console.log("unregistering service worker, bye bye...");
          registration.unregister();
        }
      }
    });

    const pathname = window.location.pathname;

    const paths = fromPathTo();

    if (paths[pathname]) {
      console.log("reidrecting to...", paths[pathname]);
      window.location.replace(paths[pathname]);
    }
  }, []);

  return (
    <div className="App">
      redirecting to <a href="https://with.zonayed.me">with.zonayed.me</a>
    </div>
  );
}

export default App;
